<template>
    <div class="main-container">
        <loader :loader="loader" :start="false"/>

        <router-view></router-view>       	

        <!-- Navbar section -->
        <!-- <HeaderElement /> -->

        <!-- breadcrumb strat -->
        <Breadcrumb>
            <template slot="breadcrumb">
                <div class="breadcrumb_box text-center">
                    <h2 class="breadcrumb-title">News</h2>
                    <!-- breadcrumb-list start -->
                    <ul class="breadcrumb-list">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'Home'}">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active">News</li>
                    </ul>
                    <!-- breadcrumb-list end -->
                </div>
            </template>
        </Breadcrumb>
        <!-- breadcrumb end -->

        <!-- portfolio item wrapper start -->
        <div class="portfolio-pages-wrapper section-space--ptb_100 border-bottom gray-gradient">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-30 wow move-up" v-for="post in posts" :key="post.id" @click="detailPost(post.id)">
                        <div class="portfolio-grid-caption">
                            <router-link to="" class="single-grid-caption">
                                <div class="single-portfolio__thumbnail">
                                    <img class="img-fluid" src="@/assets/img/portfolio/portfolio-04-481x325.jpg" alt="image">
                                </div>
                            </router-link>
                            <div class="post-info">
                                <div class="post-categories" v-if="!utils.checkIsUndefinedOrEmpty(post.date)">
                                    <router-link to="">{{ utils.formatLocaleDate(post.date) }}</router-link>
                                </div>
                                <h5 class="post-title font-weight--bold" v-if="!utils.checkIsUndefinedOrEmpty(post.title)">
                                    <router-link to="" v-html="post.title.rendered "></router-link>
                                </h5>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-12">
                        <div class="load-more-button text-center">
                            <button class="ht-btn ht-btn-md ht-btn--outline loadMore">Load More</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- portfolio item wrapper end -->

        <!-- footer section -->
        <FooterMain />

        <!-- offcanvas search component -->
        <OffcanvasSearchBox />

        <!-- off-canvas mobile menu -->
        <OffCanvasMobileMenu />

    </div>
</template>

<script>
    // import HeaderElement from '@/components/HeaderElement'
    import Breadcrumb from '@/components/Breadcrumb'
    import FooterMain from '@/components/FooterMain'
    import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
    import Blog from '../services/Blog.js'
import utils from '../constants/utils.js'

    export default {
        name: 'PortfolioGridCaption',
        components: {
            // HeaderElement,
            Breadcrumb,
            FooterMain,
            OffcanvasSearchBox,
            OffCanvasMobileMenu
        },

        data () {
            return {
                posts: [],
                loader: false,
                utils: utils
            }
        },
        async mounted() {
            this.loader = true
            await Blog.fetchPosts()
                .then(resp => {
                    if(utils.checkIsUndefinedOrEmpty(resp.data)){
                        this.loader = false
                        return
                    }
                    this.posts = resp.data
                })
            this.loader = false
        },
        methods: {
            detailPost(id) {
                this.$router.push({name: "NewsDetails", params: {"id": id}})
            }
        },

        metaInfo: {
            title: 'Dualbit - News',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

