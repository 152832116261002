<template>
    <div class="payment-staps-way pt-5 bg-photo" id="service">
        <div class="payment-typed-text-wrap" id="sevice">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 wow move-up">
                        <div class="section-title-wrapper text-center section-space--mb_60">
                            <h2 class="section-title font-width-light mb-15  wow move-up">Say hello to auto process</h2>
                            <p class="wow move-up">Financial transactions turn from physical to digital.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment-staps-way-wrap section-space--pt_60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- payment Process Step One Start -->
                        <div class="payment-process-step__one wow move-up">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="payment-process-step__media text-center">
                                        <img src="../../assets/img/screenshots/screenshot_1.jpg" class="img-fluid max_height_700" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-6 ">
                                    <div class="payment-process-step__content float-md-right">
                                        <div class="payment-process__inner">
                                            <h4 class="font-weight--blod section-space--mb_40">{{ $t('message.aboutPoolsuite') }}
                                            </h4>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textPoolsuite1')">
                                            </div>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textPoolsuite2')">
                                            </div>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textPoolsuite3')">
                                            </div>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textPoolsuite4')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- payment Process Step One End -->

                        <!-- payment Process Step Two Start -->
                        <div class="payment-process-step__two section-space--pt_60 wow move-up">
                            <div class="row align-items-center">
                                <div class="col-lg-5 order-2 order-lg-1">
                                    <div class="payment-process-step__content float-md-left">
                                        <div class="payment-process__inner">
                                            <h4 class="font-weight--blod section-space--mb_40">{{ $t('message.aboutAppAndroid') }}
                                            </h4>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textApp1')">
                                            </div>
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.technologiesUsed')">
                                            </div>
                                            <ul class="check-list green-check-list section-space--mt_30">
                                                <li class="list-item">Kotlin</li>
                                                <li class="list-item">{{ $t('message.openGles') }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 order-1 order-lg-2">
                                    <div class="app-showcase-process-step__media text-center">
                                        <img src="../../assets/img/screenshots/screenshot_2.jpg" class="img-fluid max_height_700" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- payment Process Step End Start -->

                        
                        <div class="payment-process-step__one wow move-up section-space--pt_60">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="payment-process-step__media text-center">
                                        <img src="../../assets/img/screenshots/screenshot_3.jpg" class="img-fluid max_height_700" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="payment-process-step__content float-md-right">
                                        <div class="payment-process__inner">
                                            <!-- <h4 class="font-weight--blod section-space--mb_40">Effortless billing with AeroLand
                                            </h4> -->
                                            <!-- <div class="sub-text section-space--mb_40" v-html="$t('message.textApp2')">
                                            </div> -->
                                            <!-- <div class="payment-process-btn section-space--mb_40">
                                                <a href="#" class="ht-btn ht-btn-md"><i class="fa fa-arrow-alt-to-bottom mr-2"></i> Try for free</a>
                                                <h6 class="sub-heading">Questions? <a href="#" class="hover-style-link hover-style-link--green">Talk to our team</a></h6>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-process-step__one wow move-up section-space--pt_60 bg-image">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="payment-process-step__content float-md-right">
                                        <div class="payment-process__inner">
                                            <!-- <h4 class="font-weight--blod section-space--mb_40">Effortless billing with AeroLand
                                            </h4> -->
                                            <div class="sub-text section-space--mb_40" v-html="$t('message.textApp2')">
                                            </div>
                                            <div class="payment-process-step__media text-center">
                                                <img src="../../assets/img/poolsuite/sunset.png" style="width: 100%;" class="img-fluid" alt="">
                                            </div>
                                            <!-- <div class="payment-process-btn section-space--mb_40">
                                                <a href="#" class="ht-btn ht-btn-md"><i class="fa fa-arrow-alt-to-bottom mr-2"></i> Try for free</a>
                                                <h6 class="sub-heading">Questions? <a href="#" class="hover-style-link hover-style-link--green">Talk to our team</a></h6>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaymentStep',
    };
</script>