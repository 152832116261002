export default {
    checkIsUndefined,
    checkIsUndefinedOrEmpty,
    formatLocaleDate
}

function checkIsUndefined(object) {
    return object === undefined || object === null
}

function checkIsUndefinedOrEmpty(object) {
    return checkIsUndefined(object) || object === ""
}

function formatLocaleDate(date) {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    var newDate = new Date(date)
    return newDate.toLocaleDateString("it-IT", options)
}