<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="section-title text-center section-space--mb_60">
                <h2 class="font-weight--light text-white mb-15 wow move-up">{{ $t("message.ourTeam") }}</h2>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="swiper-container team-slider__container">
                <swiper :options="swiperOption">
                    <div class="swiper-slide" v-for="member in team" :key="member.name">
                        <div class="grid-item wow move-up">
                            <!-- ht-team-member Start -->
                            <div class="ht-team-member text-center">
                                <div class="ht-team-member--inner">
                                    <!-- team-image Start -->
                                    <div class="team-image">
                                        <img class="img-fluid" :src="member.image" :alt="member.name">
                                    </div>
                                    <!-- team-image End -->
                                    <!-- team-info Start -->
                                    <div class="team-info text-center">
                                        <h5 class="name">{{member.name}}</h5>

                                        <div class="position">{{ member.position }}</div>

                                        <div class="social-networks-rounded mt-10">
                                            <ul class="list ht-social-networks solid-rounded-icon">
                                                <!-- <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                </li> -->
                                                <li class="item" v-if="member.social.twitter != ''">
                                                    <a target="_blank" :href="member.social.twitter" class="social-link hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li class="item" v-if="member.social.instagram != ''">
                                                    <a target="_blank" :href="member.social.instagram" class="social-link hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- team-info End -->
                                </div>
                            </div>
                            <!-- ht-team-member End -->
                        </div>
                    </div>
                </swiper>
                <!-- Add Arrows -->
                <div class="swiper-pagination swiper-pagination-2 section-space--mt_50"></div>
            </div>
        </div>
    </div>
                
</template>

<script>
    export default {
        name: 'TeamTwo',
        data () {
            return {
                swiperOption: {
                    speed: 800,
                    loop: false,
                    slidesPerView: 4,
                    spaceBetween : 30,
                    // pagination: {
                    //     el: '.swiper-pagination',
                    //     clickable: true
                    // },
                     breakpoints: {
                        1499:{
                            slidesPerView : 4
                        },

                        991:{
                            slidesPerView : 2
                        },

                        767:{
                            slidesPerView : 1

                        },

                        575:{
                            slidesPerView : 1
                        }
                    }
                },
                team: [
                    {
                        name: "Alberto",
                        position: "iOS Developer & UI/UX ",
                        image: require("@/assets/img/team/albe.png"),
                        social: {
                            instagram: "https://www.instagram.com/palla89/",
                            twitter: "https://twitter.com/palla8900"
                        }
                    },
                    {
                        name: "Simone",
                        position: "Full Stack Developer",
                        image: require("@/assets/img/team/simo2.png"),
                        social: {
                            instagram: "https://www.instagram.com/simmyg89/",
                            twitter: "https://twitter.com/simmyg89"
                        }
                    },
                    {
                        name: "Francesco",
                        position: "Android & iOS Developer",
                        image: require("@/assets/img/team/francesco.png"),
                        social: {
                            instagram: "https://www.instagram.com/ciaosonokekko/",
                            twitter: "https://twitter.com/ciaosonokekko"
                        }
                    },
                    {
                        name: "Franz",
                        position: "Frontend & Android Developer",
                        image: require("@/assets/img/team/franz.png"),
                        social: {
                            instagram: "https://www.instagram.com/have_you_met_franz/",
                            twitter: ""
                        }
                    }
                ]
            }
        }
    }
</script>
