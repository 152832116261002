<template>
    <div id="loading" v-show="loader === true">
        <vue-loaders-pacman scale="1.5" color="#27397c"/>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        loader: Boolean
    }

}
</script>

<style>

</style>