<template>
    <div class="how-it-works-area how-it-works-bg section-space--pb_120">
        <div class="container-fluid container-fluid--cp-150">
            <div class="row align-items-center">
                <div class="col-lg-6 video-popup video-link">
                    <!-- <silentbox-single src="https://www.youtube.com/watch?v=9No-FiEInLA"> -->
                        <div class="single-popup-wrap">
                            <img class="img-fluid" src="../../assets/img/agency/coding.svg" alt="">
                            <!-- <div class="ht-popup-video video-button video-overlay">
                                <div class="video-mark">
                                    <div class="wave-pulse wave-pulse-1"></div>
                                    <div class="wave-pulse wave-pulse-2"></div>
                                </div>
                                <div class="video-button__five">
                                    <div class="video-play">
                                        <span class="video-play-icon"></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    <!-- </silentbox-single> -->
                </div>
                <div class="col-lg-6">
                    <div class="how-it-works__content text-left section-space--mb_60">
                        <h3 class="section-title font-weight--light mb-15 wow move-up">{{ $t("message.whyUs") }}</h3>
                        <ul class="check-list green-check-list section-space--mb_50 wow move-up">
                            <li class="list-item">{{ $t("message.youngTeam") }} </li>
                            <li class="list-item">{{ $t("message.specializedTeam") }}</li>
                            <li class="list-item">{{ $t("message.bestTechnologies") }} </li>
                            <li class="list-item">{{ $t("message.qualityFirst") }} </li>
                        </ul>
                        <h6 class="sub-heading wow move-up">{{ $t("message.weCanHelpYou") }} <a href="#contact" class="hover-style-link hover-style-link--green">{{ $t("message.writeUs") }}</a></h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeatureAgency',
    };
</script>