<template>
    <div class="hero-software hero-swiper-btn" id="home">
        <div class="container_f1">
            <video-background 
                :src="require('@/assets/video/video-bg.mp4')"
                style="height: 100vh;"
                overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)" 
            >
                <div class="swiper-slide bg-cover">
                        <div class="hero-item">
                            <div class="hero-content text-center">
                                <h4 class="hero-content-subtitle">{{ $t("message.developApp") }}</h4>
                                <h2 class="h1 hero-content-title">{{ $t("message.realizeIdeas") }}</h2>
                                <div class="hero-btn">
                                    <!-- <button class="ht-btn ht-btn-md ht-btn--gradient2">Subscribe</button> -->
                                    <a href="#contact" class="ht-btn ht-btn-md">{{ $t("message.contactUs") }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </video-background>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                swiperOption: {
                    speed: 1500,
                    loop: true,
                    effect: 'fade',
                    autoplay: false,
                    spaceBetween : 30,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                }
            };
        }
    };
</script>

<style lang="scss" scoped> 
    @import '../../assets/scss/variabls.scss';
    .hero-item {
        height: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
        // responsive
        @media #{$desktop-device, $tablet-device}{
            height: 600px;
        }
        @media #{$large-mobile}{
            height: 450px;
        }
        @media #{$extra-small-mobile}{
            height: 380px;
        }
    }
    .hero-content {
        width: 1000px;
        &-subtitle {
            color: $white;
            font-weight: 400;
            padding-bottom: 20px;
        }
        &-title {
            color: $white;
            font-size: 80px;
            margin-bottom: 0;
            // responsive
            @media #{$large-mobile}{
                font-size: 40px;
            }
            @media #{$small-mobile}{
                font-size: 28px;
            }
        }
        .sub-heading {
            color: $white;
            font-size: 16px;
            padding-top: 40px;
        }
        .hero-btn {
            margin-top: 40px;
            .ht-btn {
                margin-right: 30px;
                @media #{$extra-small-mobile}{
                    margin-right: 5px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &--gradient2 {
                    background: linear-gradient(90deg, rgb(240, 168, 107) 0%, rgb(225, 118, 67) 100%);
                }
            }
        }
    }
    .slider-1 {
        background-image: url('../../assets/img/hero/agency/digital-agency-01.jpg');
    }
    .slider-2 {
        background-image: url('../../assets/img/hero/agency/digital-agency-02.jpg');
    }
</style>

