<template>
    <div class="main-container">

        <router-view></router-view>

        <!-- Navbar section -->
        <HeaderElement />

        <!-- breadcrumb strat -->
        <Breadcrumb>
            <template slot="breadcrumb">
                <div class="breadcrumb_box text-center">
                    <h2 class="breadcrumb-title">News</h2>
                    <!-- breadcrumb-list start -->
                    <ul class="breadcrumb-list">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'Home'}">Home</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'Clients'}">Clienti</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'Projects', params: {client: client}}">Progetti</router-link>
                        </li>
                        <li class="breadcrumb-item active" v-if="info.title != undefined" v-html="info.title"></li>
                    </ul>
                    <!-- breadcrumb-list end -->
                </div>
            </template>
        </Breadcrumb>
        <!-- breadcrumb end -->

        <!-- portfolio details wrapper start -->
        <div class="portfolio-details-wrapper section-space--ptb_100">
            <div class="container">
                <div class="row portfolio-details-feature-wrap">
                    <!-- <div class="col-lg-6">
                        <div class="portfolio-details-one-images mb-30">
                            <img class="img-fluid border-radus-5" src="@/assets/img/portfolio/portfolio-01-670x479.jpg" alt="image">
                        </div>
                        <div class="portfolio-details-one-images mb-30">
                            <img class="img-fluid border-radus-5" src="@/assets/img/portfolio/portfolio-02-670x479.jpg" alt="image">
                        </div>
                        <div class="portfolio-details-one-images mb-30">
                            <img class="img-fluid border-radus-5" src="@/assets/img/portfolio/portfolio-04-670x479.jpg" alt="image">
                        </div>
                    </div> -->
                    <div class="col-lg-12">
                        <div class="portfolio-details-content">
                            <div class="portfolio-details-title">
                                <a class="portfolio-categories" href="#">Concept</a>
                                <h3 class="portfolio-details-title" v-if="info.title != undefined" v-html="info.title"></h3>
                            </div>
                            <div class="text mt-20">
                                <div v-if="info.text != undefined" v-html="info.text"></div>
                            </div>
                            <div class="portfolio-details-table mt-30">
                                <table class="mb-0">
                                    <tbody>
                                        <tr class="portfolio-details-date">
                                            <td class="label">Data</td>
                                            <td>{{ info.date }}</td>
                                        </tr>

                                        <tr class="portfolio-details-client">
                                            <td class="label">Client</td>
                                            <td>{{ client }}</td>
                                        </tr>

                                        <tr class="portfolio-details-tags">
                                            <td class="label">Tags</td>
                                            <td>
                                                <a href="#" rel="tag">concept</a>, 
                                                <a href="#" rel="tag">digital</a>
                                            </td>
                                        </tr>

                                        <tr class="portfolio-details-share">
                                            <td class="label">Share</td>
                                            <td class="portfolio-sharing-list">
                                                <div class="inner">
                                                    <a class="hint--bounce hint--top hint--primary twitter" target="_blank" aria-label="Twitter" href="https://twitter.com/">
                                                        <i class="fab fa-twitter"></i>
                                                    </a>
                                                    <a class="hint--bounce hint--top hint--primary facebook" target="_blank" aria-label="Facebook" href="https://www.facebook.com/">
                                                        <i class="fab fa-facebook-f"></i>
                                                    </a>
                                                    <a class="hint--bounce hint--top hint--primary linkedin" target="_blank" aria-label="Linkedin" href="https://www.linkedin.com/">
                                                        <i class="fab fa-linkedin"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- portfolio details wrapper end -->

        <!-- footer section -->
        <FooterMain />

        <!-- offcanvas search component -->
        <OffcanvasSearchBox />

        <!-- off-canvas mobile menu -->
        <OffCanvasMobileMenu />

    </div>
</template>

<script>
    import HeaderElement from '@/components/HeaderElement'
    import Breadcrumb from '@/components/Breadcrumb'
    import FooterMain from '@/components/FooterMain'
    import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
    import jsonProjects from '../../constants/jsonProjects.json'

    export default {
        name: 'PortfolioGrid',
        components: {
            HeaderElement,
            Breadcrumb,
            FooterMain,
            OffcanvasSearchBox,
            OffCanvasMobileMenu
        },
        data() {
            return {
                client: "",
                project: "",
                info: {}
            }
        },
        mounted () {
            this.client = this.$route.params.client
            this.project = this.$route.params.project
            this.info = jsonProjects[this.client][this.project]
        },

        metaInfo: {
            title: 'Aeroland - Portfolio Details',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

