
import Home from '../pages/Home'
import News from '../pages/News'
// import Clients from '../pages/projects/Clients'
// import Projects from '../pages/projects/Projects'
import Project from '../pages/projects/Project'
import NewsDetails from '../pages/NewsDetails'
import Poolsuite from '../pages/Poolsuite'
import WorkWithUs from '../pages/WorkWithUs'
import NotFound from '../pages/elements/404'

export const routes = [
    {
        path:'/',
        name: 'Home',
        component: Home
    },
    {
        path:'/news',
        name: 'News',
        component: News
    },
    {
        path:'/news/:id',
        name: 'NewsDetails',
        component: NewsDetails
    },
    {
        path:'/poolsuite',
        name: 'Poolsuite',
        component: Poolsuite
    },
    {
        path:'/work-with-us',
        name: 'WorkWithUs',
        component: WorkWithUs
    },
    // {
    //     path:'/clients',
    //     name: 'Clients',
    //     component: Clients
    // },
    // {
    //     path:'/clients/:client',
    //     name: 'Projects',
    //     component: Projects
    // },
    {
        path:'/clients/:client/:project',
        name: 'Project',
        component: Project
    },
    {
        path:'*',
        name: 'NotFound',
        component: NotFound
    },
];