<template>
	<div class="main-container">

		<!-- Navbar section -->
		<!-- <HeaderAgency /> -->

		<!-- hero section -->
		<HeroDigitalAgency />


		<div class="start-ups-feature startup-about-feature pt-5" id="about">
			<div class="container">
				<div class="section-title-wrapper text-center section-space--mb_60">
					<h1 class="section-title mb-30 wow move-up" v-html="$t('message.weAreHiring')"></h1>
				</div>
				<div class="hero-btn text-center">
					<!-- <button class="ht-btn ht-btn-md ht-btn--gradient2">Subscribe</button> -->
					<router-link :to="{name: 'WorkWithUs'}" class="ht-btn ht-btn-md">{{ $t("message.seeOpenPosition") }}</router-link>
				</div>
				<!-- feature component -->
				<!-- <StartUpFeatureTwo /> -->
			</div>
		</div>

		<!-- service section -->
		<div class="start-ups-feature startup-about-feature section-space--pt_120 section-space--pb_60 ">
			<div class="container">
				<div class="section-title-wrapper text-center section-space--mb_60">
					<h2 class="section-title mb-30 wow move-up" v-html="$t('message.offerYourBusiness')" id="about"></h2>
				</div>
				<!-- feature component -->
				<StartUpFeatureTwo />
			</div>
		</div>
		<div class="start-ups-feature mb-4" id="platform">
			<div class="container">
				<div class="section-title text-center section-space--mb_60">
					<h2 class="font-weight--bold mb-15 wow move-up">{{ $t('message.multiplePlatforms') }}</h2>
					<span class="section-text wow move-up">{{ $t('message.ourFavourites') }}</span>
				</div>
				<!-- start up feature component -->
				<StartUpFeature />
			</div>
		</div>

		<!-- feature section -->
		<FeatureAgency />

		<!-- team section -->
		<!-- team component -->
		<div class="team-member-wrapper section-space--ptb_120 default-bg" id="team">
			<div class="container">
				<TeamTwo />
			</div>
		</div>
		<!-- <TeamAgency /> -->

		<!-- project gallery -->
		<!-- <ProjectAgency /> -->

		<!-- CLIENTI -->
		<!-- <CarouselStartup /> -->


		<ContactForm />

		<!-- footer section -->
		<FooterMain />


	</div>
</template>

<script>
	// import HeaderAgency from "@/components/HeaderAgency";
	import HeroDigitalAgency from "@/components/sections/HeroDigitalAgency";
	import FeatureAgency from "@/components/sections/FeatureAgency";
	// import ProjectAgency from "@/components/sections/ProjectAgency";
	import FooterMain from "@/components/FooterMain";
	import ContactForm from '../components/ContactForm'
	import StartUpFeatureTwo from '../components/StartUpFeatureTwo'
	import StartUpFeature from '../components/StartUpFeature'
	import TeamTwo from '../components/TeamTwo'

	export default {
		name: "app",
		components: {
			// HeaderAgency,
			HeroDigitalAgency,
			FeatureAgency,
			// ProjectAgency,
			FooterMain,
			ContactForm,
			StartUpFeatureTwo,
			StartUpFeature,
			TeamTwo,
		},
		methods: {},

		metaInfo: {
			title: 'Dualbit',
			htmlAttrs: {
				lang: 'en',
				amp: true
			}
		}
	};
</script>

<style lang="scss">
	@import '../assets/scss/_variabls.scss';
    @import '../assets/scss/page-section/_digital-agency.scss';
</style>

