export default {
    contactUs: "Contattaci",
    aboutUs: "CHI SIAMO",
    team: "IL TEAM",
    contacts: "CONTATTI",
    developApp: "Sviluppo App e Software su misura per te.",
    realizeIdeas: "REALIZZA LE TUE IDEE",
    offerYourBusiness: "Offriamo al tuo business la soluzione<br/> migliore per crescere.",
    appIosAndroid: "App iOS e Android",
    textApp: "<strong>Il nostro core business!</strong><br/>Sviluppiamo App native per Smartphone e Tablet per prestazioni al top e creare un'esperienza di utilizzo fuori ogni scala.",
    projectB2b: "Progetti B2B e B2C",
    textProject: "Ogni cliente è importante, ha la propria storia e i propri obbiettivi da raggiungere. E così come un sarto ricama vestiti su misura, noi troviamo ed offriamo soluzioni ad-hoc anche per il cliente più esigente.",
    ecommerce: "Siti Vetrina ed E-commerce",
    ecommerceText: "Allestiamo con voi negozi e vetrine virtuali che rispecchino fedelmente la vostra attività ed identità aziendale.",
    webPortals: "Portali Web Aziendali",
    webPortalsText: "DualBit è gioco di squadra: ci facciamo carico della vostra idea, la progettiamo insieme e la sviluppiamo nella sua forma migliore.",
    systemsActivity: "Attività sistemistiche e di <br/>IT Security",
    systemsActivityText: "Proponiamo le soluzioni ideali per la vostra infrastruttura di rete, per prestazioni e sicurezza senza precedenti.",
    andSoOn: "...E molto altro!",
    andSoOnText: "Passione e genuina curiosità ci hanno spinti a sperimentare in prima persona le tecnologie più innovative, quali Realtà Aumentata, Machine Learning e bot: rimanere aggiornati è un gioco vincente!<br/><strong>Con noi tutto è possibile!</strong>",
    multiplePlatforms: "Lavoriamo su piú piattaforme",
    ourFavourites: "Ecco le nostre preferite",
    whyUs: "Perché affidarsi a noi",
    youngTeam: "Il nostro team è giovane e dinamico, ama le sfide e ogni membro che lo compone ha diversi anni di esperienza alle spalle.",
    specializedTeam: "Ogni membro del team è altamente specializzato nella propria area. Questo ci permette di offrire sempre il miglior servizio.",
    bestTechnologies: "Utilizziamo soltanto le migliori e comprovate tecnologie, ma tenendoci sempre aggiornati sulle nuove.",
    qualityFirst: "Mettiamo sempre la qualità al primo posto.",
    weCanHelpYou: "Non sai se possiamo esserti utile?",
    writeUs: "Scrivici, ne rimarrai sorpreso!",
    ourTeam: "Il Nostro Team",
    useForm: "Usa questo form per chiedere un preventivo, farci una domanda o anche solo un saluto. Risponderemo prima possibile!",
    firstName: "Nome *",
    lastName: "Cognome *",
    email: "Email *",
    telephone: "Telefono",
    company: "Azienda",
    object: "Oggetto *",
    message: "Messaggio *",
    send: "Invia",
    someScreenshot: "Ecco Acuni Screenshot",
    exclusivePartnership: "Una Partnership Esclusiva!",
    textPoolsuite: "Testo",
    textPoolsuite1: "The ultra-summer internet radio station, playing an infinity pool of summer sounds 24/7 and transporting you to a virtual vacation where the sun never sets.<br/>Swimwear optional.",
    textPoolsuite2: "PoolsuiteFM was conceived by Marty Bell and his amazing team almost 7 years ago, and it consists in a retro-nostalgic-website where you can always live in Summer, even when it’s -5°C outside.",
    textPoolsuite3: "The music and playlists, curated by Marty himself, are a constant boost of serotonin that improves mood and productivity, everytime you need it!",
    textPoolsuite4: "Last year Marty and his team published Poolsuite’s iOS app, that was also one of the finalists in 2021 Apple Design Awards!",
    aboutPoolsuite: "About Poolsuite",
    aboutAppAndroid: "But what about the Android App? ",
    textApp1: "This is the part where DualBit steps in, and now you can live the full Poolsuite experience in the new, shining and totally native Android app!",
    technologiesUsed: "Technologies used:",
    openGles: "OpenGLES for filters",
    textApp2: "iOS app was originally written in React Native, with the purpose of writing once and getting iOS and Android version effortlessly. Unfortunately, with apps where a strong set of customisations must be made, the Android app was having too many problems, so the development was postponed. We stepped in proposing a full rewrite in Kotlin, Android native language and, two months later, you can see it on the GooglePlay store! ",
    weAreHiring: "We Are Hiring!",
    candidate: "Candidati",
    workWithUs: "LAVORA CON NOI",
    seeOpenPosition: "Vedi le posizioni aperte"
}