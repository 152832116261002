import axios from 'axios'
import constants from '../constants/constants.js'
// import store from '../store.js'
// import router from '../router/index.js'

const axiosApi = axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? constants.DEVELOP_API_URL : constants.PRODUCTION_API_URL
    baseURL: constants.API_URL
})

// axiosApi.setAuthHeaderWithToken = function(token) {
//     if(token === undefined) {
//         token = store.state.token
//     }
//     axiosApi.defaults.headers.common['Authorization'] = 'JWT ' + token
// }

// axiosApi.interceptors.response.use(function (response) {
//     return response
//   }, function (error) {
//     if(error.response.status === 404) {
//       router.push({name: 'NotFound'})
//     } 
//     return Promise.reject(error)
//   })

export default() => {
    return axiosApi
}