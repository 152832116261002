<template>
    <div id="app" :class="{'dark': isDark}">
        <notifications group="message" position="top right" animation-type="velocity"/>
        <header-agency :isDark="isDark" @changeDark="changeDark"/>
        <router-view></router-view>
        <cookie-law theme="dark-lime" buttonText="Accetto">
            <div slot="message">
                Accetta la nostra politica privacy. Più dettagli <router-link to="legal-notes">qui</router-link>
            </div>

        </cookie-law>

    </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import HeaderAgency from './components/Header.vue';
    export default {
        name: 'app',
        components: { CookieLaw, HeaderAgency },
        data() {
            return {
                isDark: false
            }
        },
        mounted() {
            this.isDark = this.dark
        },
        methods: {
            changeDark(isDark) {
                this.isDark = isDark
                localStorage.setItem('dark', isDark)
            }
        },
        computed: {
            dark() {
                return JSON.parse(localStorage.getItem('dark')) === true
            }
        }
    }
</script>

