<template>
    <div class="contact-form-section section-space--ptb_120" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 order-2 order-lg-1">
                    <div class="section-title text-left section-space--mb_60">
                        <h2 class="font-weight--bold mb-15 wow move-up">{{ $t("message.contactUs") }}!</h2>
                        <span class="section-text_left wow move-up">{{ $t("message.useForm") }}</span>
                    </div>
                    <div class="contact-from-wrapper wow move-up">
                        <form id="contact-form">
                            <div class="contact-page-form">
                                <div class="contact-input">
                                    <div class="contact-inner">
                                        <input name="con_name" v-model="name" type="text" :placeholder="$t('message.firstName')">
                                    </div>
                                    <div class="contact-inner">
                                        <input name="con_surname" v-model="surname" type="text" :placeholder="$t('message.lastName')">
                                    </div>
                                </div>
                                <div class="contact-input">
                                    <div class="contact-inner">
                                        <input name="con_name" v-model="email" type="email" :placeholder="$t('message.email')">
                                    </div>
                                    <div class="contact-inner">
                                        <input name="con_telephone" v-model="telephone" type="tel" :placeholder="$t('message.telephone')">
                                    </div>
                                </div>
                                <div class="contact-inner">
                                    <input name="con_company" v-model="company" type="text" :placeholder="$t('message.company')">
                                </div>
                                <div class="contact-inner">
                                    <input name="con_subject" v-model="subject" type="text" :placeholder="$t('message.object')">
                                </div>
                                <div class="contact-inner contact-message">
                                    <textarea name="con_message" v-model="message" :placeholder="$t('message.message')"></textarea>
                                </div>
                                <div class="contact-inner">
                                    <input name="con_file" accept="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" @change="onFileChange" type="file" class="h-100 py-3" :placeholder="$t('message.object')">
                                </div>
                                <vue-recaptcha ref="recaptcha" @verify="onVerify" id="recaptchaResponse" v-model="recaptcha" sitekey="6LePwZcaAAAAALzdS9W5k6vLQAGzlFmi0fgefR--" class="mb-3" :loadRecaptchaScript="true"></vue-recaptcha>
                                <!-- <input type="hidden" v-model="recaptcha" name="recaptcha_response" id="recaptchaResponse" ref="recaptchaResponse" /> -->
                                <div class="contact-submit-btn">
                                    <button class="ht-btn ht-btn-md" type="Invia" @click.prevent="submit">{{ $t("message.send") }}</button>
                                    <p class="form-messege"></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 order-1 order-lg-2">
                    <div class="peatures_image-wrap section-space--mb_40 image-mail">
                        <div class="image text-center wow move-up">
                            <img class="img-fluid" src="../assets/img/features/mail.svg" alt="contact thumb">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios'
import utils from '../constants/utils.js'

export default {
    name: 'ContactForm',
    data() {
        return {
            name: "",
            surname: "",
            email: "",
            subject: "",
            telephone: "",
            message: "",
            recaptcha: "",
            loader: false,
            company: "",
            file: null
        }
    },
    components: {
        VueRecaptcha
    },
    computed: {
        defaultObject() {
            if (Object.keys(this.$route.query).length === 0) {
                return ""
            }
            if (this.$route.query.hiring === null || this.$route.query.hiring === undefined ||
                this.$route.query.hiring === "" || this.$route.query.hiring === 'false') {
                return ""
            }
            var object = "Candidatura"
            if (this.$route.query.position !== null && this.$route.query.position !== undefined &&
                this.$route.query.position !== "") {
                object += ` - ${this.$route.query.position}`
            }
            return object
        }
    },
    mounted() {
        this.subject = this.defaultObject
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            this.file = files[0]
        },
        async submit() {
            // var params = {
            //     name: this.name,
            //     surname: this.surname,
            //     email: this.email,
            //     subject: this.subject,
            //     telephone: this.telephone,
            //     message: this.message,
            //     recaptcha: this.recaptcha,
            //     company: this.company,
            //     file: this.file
            // }
            if (utils.checkIsUndefinedOrEmpty(this.name) || utils.checkIsUndefinedOrEmpty(this.surname) || utils.checkIsUndefinedOrEmpty(this.email) ||
                utils.checkIsUndefinedOrEmpty(this.subject) || utils.checkIsUndefinedOrEmpty(this.message) || utils.checkIsUndefinedOrEmpty(this.recaptcha)) {
                this.$swal('Attenzione', 'Compila tutti i campi obbligatori', 'warning')
                // this.$notify({
                //     group: 'message',
                //     type: 'warn',
                //     text: 'Compila tutti i campi obbligatori'
                // });
                return
            }
            let params = new FormData();
            params.append('name', this.name);
            params.append('surname', this.surname);
            params.append('email', this.email);
            params.append('subject', this.subject);
            params.append('telephone', this.telephone);
            params.append('message', this.message);
            params.append('recaptcha', this.recaptcha);
            params.append('company', this.company);
            params.append('file', this.file);
            this.loader = true
            await axios.post("php/base.php", params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(resp => {
                    if (resp.data.error === true) {
                        // this.$notify({
                        //     group: 'message',
                        //     type: 'error',
                        //     text: resp.data.message
                        // });
                        this.$swal('Errore', resp.data.message, 'error')
                    } else {
                        // this.$notify({
                        //     group: 'message',
                        //     type: 'success',
                        //     text: resp.data.message
                        // });
                        this.$swal('Inviato', resp.data.message, 'success')
                    }
                })
                .catch(err => {
                    this.$notify({
                        group: 'message',
                        type: 'error',
                        text: err
                    });
                })
            this.loader = false
        },
        onVerify(token) {
            this.recaptcha = token
        }
    }
}
</script>