<template>
    <div class="row">
        <div v-for="item in items" :key="item.id" class="col-lg-4 col-md-6 wow move-up">
            <div class="service-grid">
                <div class="ht-service-box--two text-center style-03">
                    <router-link to="">
                        <div class="image mb-20">
                            <img :src="item.icon" class="img-fluid" alt="aeroland-startup-box-icon-01">
                        </div>
                        <div class="content">
                            <h6 class="heading" v-html="item.heading"></h6>
                            <div class="service_text" v-html="item.text"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StartUpFeatureTwo',
        data (){
            return {
                items: [
                    {
                        icon: require('../assets/img/icons/aeroland-startup-box-icon-02.png'), 
                        heading: this.$t("message.appIosAndroid"), 
                        text: this.$t("message.textApp")
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-startup-box-icon-03.png'), 
                        heading: this.$t("message.projectB2b"), 
                        text: this.$t("message.textProject")
                    },
                    {
                       icon: require('../assets/img/icons/aeroland-startup-box-icon-01.png'), 
                       heading: this.$t("message.ecommerce"), 
                       text: this.$t("message.ecommerceText")
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-startup-box-icon-04.png'), 
                        heading: this.$t("message.webPortals"), 
                        text: this.$t("message.webPortalsText")
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-startup-box-icon-05.png'), 
                        heading: this.$t("message.systemsActivity"), 
                        text: this.$t("message.systemsActivityText")
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-startup-box-icon-06.png'), 
                        heading: this.$t("message.andSoOn"), 
                        text: this.$t("message.andSoOnText")
                    },
                ]
            }
        }
    }
</script>
