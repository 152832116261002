import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueSilentbox from 'vue-silentbox'
import {VueMasonryPlugin} from 'vue-masonry'
import WOW from 'wow.js/dist/wow.js'
import VueMeta from 'vue-meta'
import VueLoaders from 'vue-loaders';
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import CookieLaw from 'vue-cookie-law'
import VueI18n from 'vue-i18n'
import messages from "./lang"

import Loader from "./components/sections/Loader.vue"
import { Plugin } from 'vue-responsive-video-background-player'

import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: "it",
	messages, // set locale messages
})

Vue.use(VueSweetalert2);

Vue.use(Plugin);

Vue.use(VueMasonryPlugin)
Vue.use(VueSilentbox)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})
Vue.use(VueLoaders);
Vue.use(Notifications, {velocity})

Vue.component("Loader",Loader)
Vue.component("CookieLaw", CookieLaw)

import 'bootstrap/dist/css/bootstrap.css'
import '../src/assets/css/fontawesome-all.min.css'
import '../src/assets/css/base.css'
import 'swiper/dist/css/swiper.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'slick-carousel/slick/slick.css'
import 'vue-loaders/dist/vue-loaders.css';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.config.productionTip = false
Vue.config.productionSourceMap = false

import { routes } from './router/index'

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
           return { selector: to.hash }
        }
        return { x: 0, y: 0 };
    }
})


new Vue({
    i18n,
    render: h => h(App),
    router,
    mounted(){
        new WOW().init();
    }
}).$mount('#app')
