import en from './en';
import it from './it';

export default {
    en: {
        message: en
    },
    it: {
        message: it
    }
}