<template>
    <div class="start-ups-feature-wrap">
        <div class="modern-grid">
            <div v-for="iconbox in iconboxs" :key="iconbox.id" class="ht-box-icon style-02 wow move-up">
                <router-link to="" class="link-secret">
                    <div class="icon-box-wrap">
                        <div class="icon react" :class="{'java':iconbox.java, 'macos':iconbox.macos, 'window':iconbox.window, 'android':iconbox.android, 'python':iconbox.python, 'vue':iconbox.vue, 'center-icon': true}">
                            <i :class="iconbox.icon"></i>
                        </div>
                        <div class="content">
                            <h6 class="heading">{{ iconbox.heading }}</h6>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StartUpFeature',
        data (){
            return {
                iconboxs: [
                    {
                        icon: 'fab fa-apple', 
                        heading: 'iOS (Swift/Obj C)', 
                        macos:true
                    },
                    {
                        icon: 'fab fa-android', 
                        heading: 'Android (Kotlin/Java)', 
                        android:true
                    },
                    {
                        icon: 'fab fa-python', 
                        heading: 'Python', 
                        python: true
                    },
                    {
                        icon: 'fab fa-vuejs', 
                        heading: 'VueJS',
                        vue: true
                    }
                ]
            }
        }
    }
</script>
