<template>
    <div class="accordion-faq wow move-up">
        <b-card no-body v-for="job in jobs" :key="job.id">
            <b-card-header header-tag="header">
                <b-button block v-b-toggle="job.id" class="faq-title">{{ job.title }}</b-button>
            </b-card-header>
            <b-collapse :id="job.id" visible>
                <b-card-body>
                    <b-card-text v-if="job.list.length > 0">
                        <ul class="attribute_list_two  wow move-up">
                            <li class="item" v-for="item in job.list" :key="item.title">
                                <div class="name">
                                    <h6>{{ item.title }}</h6>
                                </div>
                                <div class="value">{{ item.value }}</div>
                            </li>
                        </ul>
                    </b-card-text>
                    <b-card-text v-html="job.text">
                        <!-- <br> -->
                        <!-- <router-link :to="{path: '/', hash: '#contact', query: {hiring: true, position: job.title}}" class="ht-btn d-block ht-btn-md">{{ $t("message.contactUs") }}</router-link> -->
                    </b-card-text>
                    
                </b-card-body>
                <b-card-footer>
                    <router-link :to="{path: '/', hash: '#contact', query: {hiring: true, position: job.title}}" class="ht-btn ht-btn-md">{{ $t("message.candidate") }}</router-link>
                </b-card-footer>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import jobsJson from '../constants/jobs.json'
    export default {
        name: 'Accordion',
        data() {
            return {
                jobs: jobsJson.jobs
            }
        }
    };
</script>