<template>
    <div class="main-container bg-poolsuite">

        <router-view></router-view>
        <!-- Navbar section -->
        <!-- <header-agency /> -->
        <FooterAppLanding />
        <PaymentStep />
        <FooterMain class="mt-5" />
        

    </div>
</template>

<script>
    // import HeaderAppLanding from '@/components/HeaderAppLanding'
    // import HeroAppLanding from '../components/HeroAppLanding'
    // import AppLandingFeature from '../components/AppLandingFeature'
    // import BannerTwo from '../components/BannerTwo'
    // import CurveShape from '../components/CurveShape'
    // import Screenshot from '../components/Screenshot'
    // import PricingThree from '../components/PricingThree'
    // import TestimonialFour from '../components/TestimonialFour'
    // import Partnership from '../components/Partnership'
    import FooterAppLanding from '../components/FooterAppLanding'
    import PaymentStep from '@/components/sections/PaymentStep'
	import FooterMain from "@/components/FooterMain";
// import HeaderAgency from '../components/HeaderAgency.vue';

    export default {
        name: 'app',
        components: {
            // HeaderAppLanding,
            // HeroAppLanding,
            // AppLandingFeature,
            // BannerTwo,
            // CurveShape,
            // Screenshot,
            // PricingThree,
            // TestimonialFour,
            // Partnership,
            FooterAppLanding,
            PaymentStep,
            FooterMain,
            // HeaderAgency
        },

        metaInfo: {
            title: 'Dualbit x Poolsuite',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

