<template>
    <b-navbar type="light" toggleable="xl" fixed="top" class="header-area" :class="{'is-sticky': scrolled}">
        <div class="container-fluid container-fluid--cp-150 position-relative">
            <b-navbar-toggle target="nav_collapse" class="d-none"></b-navbar-toggle>
            <b-navbar-brand class="navbar-brand" to="/"><img :src='"../assets/img/logo/dualbit.png"' alt="logo"></b-navbar-brand>
            <!-- <b-btn-group class="header-config-wrapper">
                <b-btn class="header-config" @click="toggleClass('addClass', 'active')"><i class="far fa-search"></i></b-btn>
                <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block" href="https://1.envato.market/c/25198880/275988/4415?subId1=hasthemes&subId2=demo&subId3=https%3A%2F%2Fthemeforest.net%2Fcart%2Fconfigure_before_adding%2F25198880%3Flicense%3Dregular%26size%3Dsource&u=https%3A%2F%2Fthemeforest.net%2Fcart%2Fconfigure_before_adding%2F25198880%3Flicense%3Dregular%26size%3Dsource">BUY Now</b-link>
                <b-link class="mobile-menu-bar d-xl-none" @click="mobiletoggleClass('addClass', 'active')"> <i class="fa fa-bars"></i></b-link>
            </b-btn-group> -->
            <b-collapse class="default-nav justify-content-end"  is-nav id="nav_collapse">
                <b-navbar-nav class="navbar-nav main-menu">
                    <b-nav-item href="/#home" class="scroll"><span>HOME</span></b-nav-item>
                    <b-nav-item href="/#about" class="scroll"><span>CHI SIAMO</span></b-nav-item>
                    <b-nav-item href="/#team" class="scroll"><span>IL TEAM</span></b-nav-item>
                    <b-nav-item href="/#project" class="scroll"><span>PROGETTI</span></b-nav-item>
                    <b-nav-item :to="{name: 'WorkWithUs'}"><span>LAVORA CON NOI</span></b-nav-item>
                    <b-nav-item :to="{name: 'News'}"><span>NEWS</span></b-nav-item>
                    <b-nav-item href="/#contact" class="scroll"><span>CONTATTI</span></b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
    export default {
        name:'HeaderElement',
        data (){
            return {
                load: false,
                limitPosition: 200,
                scrolled: false,
                lastPosition: 500,
            }
        },
        methods: {
            // sticky menu script
            handleScroll() {
                if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                    this.scrolled = true;
                    // move up!
                } 
                if (this.lastPosition > window.scrollY) {
                    this.scrolled = true;
                    // move down
                }
                this.lastPosition = window.scrollY;
                this.scrolled = window.scrollY > 50;
            },

            // offcanvas searchbox
            toggleClass(addRemoveClass, className) {
                const el = document.querySelector('#search-overlay');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            // offcanvas searchbox
            mobiletoggleClass(addRemoveClass, className) {
                const el = document.querySelector('#offcanvas-menu');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
        }, 
        created() {
            window.addEventListener("scroll", this.handleScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
    }
</script>

<style lang="scss">
    .mobile-menu-bar {
        color:#21409A;
        font-size: 25px;
        border: 1px solid#21409A;
        padding: 8px 10px 10px;
        line-height: 1;
        height: 42px;
        margin-top: 6px;
        border-radius: 4px;
    }

    .element-menu {
        [aria-expanded="false"] {
            background-color: transparent;
        }
        .dropdown-item {
            font-size: 14px;
            &:hover {
                color: #27397c;
                background-color: transparent;
            }
        }
        .dropdown-menu {
            border: none;
            border-bottom: 3px solid #27397c;
            margin-top: -2px;
        }
    }
    .mega-menu {
        .dropdown-menu {
            min-width: 100%;
        }
        .mega-submenu {
            width: 33.33%;
            float: left;
            .nav-link {
                padding: 20px 10px !important;
                .mega-title {
                    .dropdown-item {
                        font-weight: 500;
                        color: #000;
                    }
                    
                }
            }
        }
    }
</style>